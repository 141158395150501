<template>
    <div id="data-list-list-view" class="data-list-container">

        <div v-if="initProgress" class="w-100 mt-5 mb-5">
            <vs-progress indeterminate color="primary"></vs-progress>
        </div>

        <new :isSidebarActive="newSidebar" @closeSidebar="toggleNewSidebar" :data="sidebarData"/>
        <update :isSidebarActive="updateSidebar" @closeSidebar="toggleUpdateSidebar" :data="sidebarData"/>
        <values :isSidebarActive="valuesSidebar" @closeSidebar="toggleValuesSidebar" :data="sidebarData"/>

        <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
                  :data="features">
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

                <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                    <!-- Action - dropnow -->
                    <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

                        <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
                            <span class="mr-2">Acciones</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                        </div>

                        <vs-dropdown-menu>
                            <vs-dropdown-item @click="editData(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                    <!-- Add new -->
                    <vs-button :disabled="initProgress" color="danger" type="border" class="mr-6" @click="addNewData">
                        Agregar
                    </vs-button>
                </div>

                <!-- Items per page -->
                <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
                    <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ features.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : features.length }} de {{ queriedItems }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                    </div>
                    <vs-dropdown-menu>

                        <vs-dropdown-item @click="itemsPerPage=10">
                            <span>10</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="itemsPerPage=20">
                            <span>20</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="itemsPerPage=30">
                            <span>30</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="itemsPerPage=40">
                            <span>40</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>

            <template slot="thead">
                <vs-th sort-key="name">Titulo</vs-th>
                <vs-th>Estado</vs-th>
                <vs-th>Acciones</vs-th>
            </template>

            <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.name }}</p>
                    </vs-td>
                    <vs-td>
                        <vs-switch @input="switchState(tr)" v-model="tr.state"/>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                        <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      @click.stop="editData(tr)"/>
                        <feather-icon class="ml-2" icon="FileTextIcon"
                                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      @click.stop="addValues(tr)"/>

                    </vs-td>
                </vs-tr>
                </tbody>
            </template>
        </vs-table>
    </div>
</template>

<script>
  import New from './New'
  import Update from './Update'
  import Values from './Values'

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()

  export default {
    components: {
      New,
      Update,
      Values
    },
    data() {
      return {
        selected: null,
        itemsPerPage: 10,
        isMounted: false,
        featuresPre: [],
        initProgress: false,

        // Data Sidebar
        newSidebar: false,
        updateSidebar: false,
        valuesSidebar: false,
        sidebarData: {}
      }
    },
    computed: {
      features: {
        get() {
          return this.$store.state.features.features
        }
      },
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx
        }
        return 0
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.features.length
      }
    },
    methods: {
      addNewData() {
        this.sidebarData = {}
        this.newSidebar = true
      },
      editData(data) {
        if (data) {
          this.sidebarData = data
          this.toggleUpdateSidebar(true)
        }
      },
      addValues(data) {
        if (data) {
          this.sidebarData = data
          this.toggleValuesSidebar(true)
        }
      },
      toggleNewSidebar(val = false) {
        this.newSidebar = val
      },
      toggleUpdateSidebar(val = false) {
        this.updateSidebar = val
      },
      toggleValuesSidebar(val = false) {
        this.valuesSidebar = val
      },
      switchState(feature) {
        let featureRef = db.collection("features").doc(feature.id)
        featureRef.update({
          state: feature.state
        }).then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Catacterísticas',
            text: 'La modificación fue aplicada.'
          })
          this.$store.dispatch('features/updateFeature', {feature})
        }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    },
    created() {
      try {
        this.initProgress = true
        //Get Features
        db.collection("features").orderBy('createdAt', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let feature = {
              id: doc.id,
              ...doc.data()
            }
            if (!feature.values) {
              feature.values = []
            }
            if (feature.tag !== 'volume' && feature.tag !== 'unity') {
              this.featuresPre.push(feature)
            }
          })
          this.$store.dispatch('features/fetchFeatures', {features: this.featuresPre})
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    mounted() {
      this.isMounted = true
    }
  }
</script>

<style lang="scss">
    #data-list-list-view {
        .vs-con-table {

            /*
              Below media-queries is fix for responsiveness of action buttons
              Note: If you change action buttons or layout of this page, Please remove below style
            */
            @media (max-width: 689px) {
                .vs-table--search {
                    margin-left: 0;
                    max-width: unset;
                    width: 100%;

                    .vs-table--search-input {
                        width: 100%;
                    }
                }
            }

            @media (max-width: 461px) {
                .items-per-page-handler {
                    display: none;
                }
            }

            @media (max-width: 341px) {
                .data-list-btn-container {
                    width: 100%;

                    .dd-actions,
                    .btn-add-new {
                        width: 100%;
                        margin-right: 0 !important;
                    }
                }
            }

            .product-name {
                max-width: 23rem;
            }

            .vs-table--header {
                display: flex;
                flex-wrap: wrap;
                margin-left: 1.5rem;
                margin-right: 1.5rem;

                > span {
                    display: flex;
                    flex-grow: 1;
                }

                .vs-table--search {
                    padding-top: 0;

                    .vs-table--search-input {
                        padding: 0.9rem 2.5rem;
                        font-size: 1rem;

                        & + i {
                            left: 1rem;
                        }

                        &:focus + i {
                            left: 1rem;
                        }
                    }
                }
            }

            .vs-table {
                border-collapse: separate;
                border-spacing: 0 1.3rem;
                padding: 0 1rem;

                tr {
                    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

                    td {
                        padding: 20px;

                        &:first-child {
                            border-top-left-radius: .5rem;
                            border-bottom-left-radius: .5rem;
                        }

                        &:last-child {
                            border-top-right-radius: .5rem;
                            border-bottom-right-radius: .5rem;
                        }
                    }

                    td.td-check {
                        padding: 20px !important;
                    }
                }
            }

            .vs-table--thead {
                th {
                    padding-top: 0;
                    padding-bottom: 0;

                    .vs-table-text {
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }

                th.td-check {
                    padding: 0 15px !important;
                }

                tr {
                    background: none;
                    box-shadow: none;
                }
            }

            .vs-table--pagination {
                justify-content: center;
            }
        }
    }
</style>
