<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Agregar Valores</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

            <div class="p-6">
                <div class="mt-5" v-for="(v, index) in feature.values" :key="index">
                    <!-- Name -->
                    <vs-input @blur="v.label= trimString(v.label)" :label="`Valor ${index+1}`" v-model="v.label"
                              class="w-full" name="label"
                              v-validate="'required|min:3'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('label')">{{ errors.first('label') }}</span>
                </div>
                <vs-button class="mr-6 mt-5" @click="addValue">Agregar</vs-button>
            </div>
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import trimString from "../../mixins/trimString"

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    name: 'ValuesFeature',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    components: {
      VuePerfectScrollbar
    },
    mixins: [trimString],
    data() {
      return {
        feature: {
          values: []
        },
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        progress: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        this.feature = {
          ...this.data
        }
        if (!this.feature.values) {
          this.feature.values = []
        }
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(async result => {
          if (result) {
            this.progress = true
            // Update feature
            let featureRef = db.collection('features').doc(this.feature.id)
            featureRef.update({
              ...this.feature,
              uid: firebase.auth().currentUser.uid,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            }).then(() => {
              this.$store.dispatch('features/updateFeature', {feature: this.feature})
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Característica',
                text: 'Característica modificada correctamente.'
              })
              this.$emit('closeSidebar')
            }).catch(() => {
              this.progress = false
              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
              })
            })
          }
        })
      },
      addValue() {
        let obj = {
          label: ''
        }
        this.feature.values.push(obj)
      },
      initValues() {
        if (this.data.id) return
        this.feature = {
          values: []
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 400px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
